import gsap from 'gsap';
import {ScrollTrigger} from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

document.addEventListener('DOMContentLoaded', function () {
  // setupSection is a reusable function for initializing filterable sections like projects or products.
  // It sets up the filtering functionality and animations for each section.
  function setupSection(sectionSelector, itemSelector, fadeDuration = 0.5) {
    const filterButtons = document.querySelectorAll(`${sectionSelector} .filter`);
    const items = document.querySelectorAll(itemSelector);
    const allItemsButton = document.querySelector(`${sectionSelector} .filter[data-filter="all"]`);

    // Updates the visual state of the last item in a list to manage spacing or styling differences.
    function updateLastVisible() {
      items.forEach(item => {
        item.classList.remove("last-visible");
      });
      // Determines which items are currently visible and marks the last one appropriately.
      const visibleItems = Array.from(items).filter(item => item.classList.contains("visible"));
      if (visibleItems.length > 0) {
        visibleItems[visibleItems.length - 1].classList.add("last-visible");
      }
    }

    // Initially marks the 'All' button as selected and ensures all items are visible.
    if (allItemsButton) {
      allItemsButton.classList.add("selected");
    }

    // Makes all items visible and animates their appearance.
    items.forEach(item => {
      item.style.display = "grid";
      item.classList.add("visible");
      gsap.fromTo(item, {opacity: 0}, {opacity: 1, duration: fadeDuration});
    });
    updateLastVisible(); // Adjusts the margin for the last visible item.

    // Adds click event listeners to filter buttons for filtering items based on their data attributes.
    if (filterButtons.length > 0) {
      filterButtons.forEach(button => {
        button.addEventListener("click", function () {
          // Skips further execution if the clicked button is already selected.
          if (this.classList.contains("selected")) {
            return;
          }

          const filter = this.dataset.filter;

          // Updates the appearance of the buttons and filters items accordingly.
          filterButtons.forEach(b => {
            b.classList.remove("selected", "inactive");
            if (b === button) {
              b.classList.add("selected");
            } else if (filter !== 'all') {
              b.classList.add("inactive");
            }
          });

          // Filters items based on their data attribute, matching the selected filter setting.
          items.forEach(item => {
            const itemFilter = item.dataset.filter;
            if (filter === 'all' || itemFilter === filter) {
              item.style.display = "grid";
              item.classList.add("visible");
              gsap.fromTo(item, {opacity: 0}, {opacity: 1, duration: fadeDuration});
            } else {
              item.style.display = 'none';
              item.classList.remove("visible");
            }
          });
          ScrollTrigger.refresh();
          updateLastVisible();
        });
      });
    }
  }

  // Initializes the functionality for both projects and products sections by calling setupSection with the appropriate selectors.
  setupSection(".projects-page", ".project-section");
  setupSection(".products-page", ".products-page .product");
});
