import Swiper from 'swiper/bundle';

const swiper = new Swiper('.swiper', {
  rewind: true,
  effect: 'fade',
  fadeEffect: {
    crossFade: true
  },
  speed: 1250,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
  },
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
    renderBullet: function (index, className) {
      return '<div class="' + className + ' custom-pagination-bullet-container"><span class="custom-pagination-bullet"></span></div>';
    },
  },
});