import gsap from 'gsap';
import {ScrollTrigger} from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

document.addEventListener('DOMContentLoaded', function () {
	let lastScrollPosition = 0;

// Function to handle the scroll event
	function handleScroll() {
		// Getting the current scroll position
		const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
		// Check if the user is scrolling up and if it's in mobile view
		if (currentScrollPosition < lastScrollPosition && currentScrollPosition > (window.innerHeight * 0.5) && window.innerWidth < 1024) {
			// Add the 'expanded' class to the .wrapper div
			document.querySelector('header .wrapper').classList.add('expanded');
		} else if (currentScrollPosition > 0 && window.innerWidth < 1024) {
			// Optional: Remove the 'expanded' class if the user is scrolling down or not in mobile view
			document.querySelector('header .wrapper').classList.remove('expanded');
		}

		// Update the last scroll position to the current position after the scroll event
		lastScrollPosition = currentScrollPosition;
	}

// Listen for scroll events on the window object
	window.addEventListener('scroll', handleScroll);

// ----------------------------------------------------------------------

	// Add 'scrolled' class to header after scroll

	const header = document.querySelector("#site-header");
	const headerHeight = header.offsetHeight;

	window.addEventListener('scroll', () => {
		const scrollPosition = window.scrollY || window.pageYOffset;

		if (scrollPosition >= headerHeight) {
			header.classList.add('scrolled');
		} else {
			header.classList.remove('scrolled');
		}
	});

	// ----------------------------------------------------------------------

	// Pin Hero images or text
	const heroTitle = document.querySelector('#hero.text-hero .fixed');
	if (heroTitle) {
		ScrollTrigger.create({
			trigger: '#hero',
			endTrigger: heroTitle,
			pin: heroTitle,
		})
	}

	const heroImage = document.querySelector('#hero .background-image');
	if (heroImage) {
		ScrollTrigger.create({
			trigger: '#hero',
			pin: heroImage,
			pinSpacing: false,
		})
	}

	// ----------------------------------------------------------------------

	// Services Accordion

	const services = document.querySelectorAll('.services-accordion .service');
	const serviceImage = document.querySelector(".services-accordion .image-wrapper img")


	if (services.length > 0) {
		const originalSource = serviceImage.src;

		services.forEach(service => {
			const toggle = service.querySelector('.service-title');

			toggle.addEventListener('click', () => {
				const serviceMoreContent = service.querySelector('.service-more-content');

				// Close any open service sections except the current one
				services.forEach(otherService => {
					if (otherService !== service && otherService.classList.contains('expanded')) {
						const otherContent = otherService.querySelector('.service-more-content');
						gsap.to(otherContent, {
							height: 0,
							autoAlpha: 0,
							duration: 0.6,
							ease: 'power2.out',
							onUpdate: function () {
								ScrollTrigger.refresh();
							},
							onComplete: function () {
								ScrollTrigger.refresh();
							}
						});
						otherService.classList.remove('expanded');
					}
				});

				// Toggle the current service section
				if (!service.classList.contains('expanded')) {
					// Measure the natural height of the content
					const height = serviceMoreContent.scrollHeight;

					gsap.to(serviceMoreContent, {
						height: height + 'px',
						autoAlpha: 1,
						duration: 0.6,
						ease: 'power2.out',
						onUpdate: function () {
							ScrollTrigger.refresh();
						},
						onComplete: () => {
							serviceMoreContent.style.height = 'auto';
							ScrollTrigger.refresh();
						},
					});
				} else {
					gsap.to(serviceMoreContent, {
						height: 0,
						autoAlpha: 0,
						duration: 0.6,
						ease: 'power2.out',
						onUpdate: function () {
							ScrollTrigger.refresh();
						},
						onComplete: function () {
							ScrollTrigger.refresh();
						}
					});
				}

				service.classList.toggle('expanded');


				// Change the image source
				if (service.dataset.imageUrl) {
					const newSource = service.dataset.imageUrl;

					const tl = gsap.timeline();
					tl.to(serviceImage, {
						duration: 0.2,
						opacity: 0,
						ease: "power2.out",
						onComplete: function () {
							// Change the image source when fade out completes
							if (service.classList.contains("expanded")) {
								serviceImage.src = newSource;
							} else {
								serviceImage.src = originalSource;
							}
						}
					});

					// Fade in
					tl.to(serviceImage, {
							duration: 0.2,
							opacity: 1,
							ease: "power2.out",
						},
					);
				} else if (serviceImage.src !== originalSource) {
					const tl = gsap.timeline();
					tl.to(serviceImage, {
						duration: 0.2,
						opacity: 0,
						ease: "power2.out",
						onComplete: function () {
							// Change the image source when fade out completes
							serviceImage.src = originalSource;

						}
					});

					// Fade in
					tl.to(serviceImage, {
							duration: 0.2,
							opacity: 1,
							ease: "power2.out",
						},
					);
				}

			});
		});

		// ----------------------------------------------------------------------

		// Pin image in services accordion section

		const servicesImage = document.querySelector('.services-accordion .image-wrapper .image-container');

		let mm = gsap.matchMedia();

		mm.add("(max-width: 1023px)", () => {
			ScrollTrigger.create({
				trigger: servicesImage,
				endTrigger: '.services-accordion',
				pin: servicesImage,
				start: "bottom bottom",
				end: "bottom bottom+=128",
			});
		})

		mm.add("(min-width: 1024px)", () => {
			ScrollTrigger.create({
				trigger: servicesImage,
				endTrigger: '.services-accordion',
				pin: servicesImage,
				start: "bottom bottom",
				end: "bottom bottom+=208",
			});
		})


	}

	// ----------------------------------------------------------------------

	// Careers Accordions
	const jobs = document.querySelectorAll('.careers .job-opening');

	if (jobs.length > 0) {
		jobs.forEach(job => {
			const toggle = job.querySelector('.job-title');

			toggle.addEventListener('click', () => {
				const jobDescription = job.querySelector('.job-description');

				// Close any open service sections except the current one
				jobs.forEach(otherJob => {
					if (otherJob !== job && otherJob.classList.contains('expanded')) {
						const otherContent = otherJob.querySelector('.job-description');
						gsap.to(otherContent, {
							height: 0,
							autoAlpha: 0,
							duration: 0.6,
							ease: 'power2.out',
						});
						otherJob.classList.remove('expanded');
					}
				});

				// Toggle the current service section
				if (!job.classList.contains('expanded')) {
					// Measure the natural height of the content
					const height = jobDescription.scrollHeight;

					gsap.to(jobDescription, {
						height: height + 'px', // GSAP cannot animate to 'auto'. Use scrollHeight for dynamic height.
						autoAlpha: 1,
						duration: 0.6,
						ease: 'power2.out',
						onComplete: () => {
							jobDescription.style.height = 'auto';
						},
					});
				} else {
					gsap.to(jobDescription, {
						height: 0,
						autoAlpha: 0,
						duration: 0.6,
						ease: 'power2.out',
					});
				}

				job.classList.toggle('expanded');


				// Change the image source


			});
		});
	}

	// ----------------------------------------------------------------------

	// FAQs Accordions
	const faqs = document.querySelectorAll('.faqs .faq');

	if (faqs.length > 0) {
		faqs.forEach(faq => {
			const toggle = faq.querySelector('.question');

			toggle.addEventListener('click', () => {
				const answer = faq.querySelector('.answer');

				// Close any open service sections except the current one
				faqs.forEach(otherFaq => {
					if (otherFaq !== faq && otherFaq.classList.contains('expanded')) {
						const otherContent = otherFaq.querySelector('.answer');
						gsap.to(otherContent, {
							height: 0,
							autoAlpha: 0,
							duration: 0.6,
							ease: 'power2.out',
						});
						otherFaq.classList.remove('expanded');
					}
				});

				// Toggle the current service section
				if (!faq.classList.contains('expanded')) {
					// Measure the natural height of the content
					const height = answer.scrollHeight;

					gsap.to(answer, {
						height: height + 'px', // GSAP cannot animate to 'auto'. Use scrollHeight for dynamic height.
						autoAlpha: 1,
						duration: 0.6,
						ease: 'power2.out',
						onComplete: () => {
							answer.style.height = 'auto';
						},
					});
				} else {
					gsap.to(answer, {
						height: 0,
						autoAlpha: 0,
						duration: 0.6,
						ease: 'power2.out',
					});
				}

				faq.classList.toggle('expanded');


				// Change the image source


			});
		});
	}


	// ----------------------------------------------------------------------

	// Members Hover Animation

	const teamMembers = document.querySelectorAll('.team .team-member');
	teamMembers.forEach((teamMember, index) => {
		teamMember.style.zIndex = index + 1;
	})

	const teamMembersWithDescription = document.querySelectorAll('.team .team-member.has-description');

	if (teamMembersWithDescription.length > 0) {
		const canHover = window.matchMedia('(hover: hover)').matches;

		teamMembersWithDescription.forEach(teamMember => {

			const descriptionContainer = teamMember.querySelector('.member-info');
			const descriptionCopyContainer = teamMember.querySelector('.member-info .copy-container');
			const descriptionText = descriptionCopyContainer.querySelectorAll('p');
			let descriptionHeight = descriptionCopyContainer.offsetHeight;

			const tl = gsap.timeline({
				paused: true,
				defaults: {
					duration: 0.6,
					ease: 'power1.out',
				}
			});

			gsap.set(descriptionContainer, {
				y: 0,
			})

			tl.to(descriptionContainer, {
				y: () => `-${descriptionHeight}px`,
			})
			tl.to(descriptionText, {
				autoAlpha: 1,
				duration: 0.6,
			}, "<")
			tl.to(descriptionCopyContainer, {
				height: 'auto',
				duration: 0.6,
			}, "<")


			if (canHover) {
				teamMember.addEventListener("mouseover", () => {
					tl.play();
				})

				teamMember.addEventListener("mouseout", () => {
					tl.reverse();
				})
			} else {
				teamMember.addEventListener("click", () => {
					descriptionHeight = descriptionCopyContainer.offsetHeight;

					teamMember.classList.toggle("active");

					if (teamMember.classList.contains("active")) {
						tl.play();
					} else {
						tl.reverse();
					}
				})
			}


		})
	}


	// ----------------------------------------------------------------------

	// Projects Page Hover Animation

	const projects = document.querySelectorAll('.projects-page .project-section');
	projects.forEach((project, index) => {
		project.style.zIndex = index + 1;
	})

	const privateProjects = document.querySelectorAll('.projects-page .project.private');

	if (privateProjects.length > 0) {
		const canHover = window.matchMedia('(hover: hover)').matches;

		privateProjects.forEach(project => {

			const descriptionContainer = project.querySelector('.title-and-location');
			const descriptionCopyContainer = project.querySelector('.private-description .copy-container');
			const descriptionText = descriptionCopyContainer.querySelectorAll('p');
			let descriptionHeight = descriptionCopyContainer.offsetHeight;

			const tl = gsap.timeline({
				paused: true,
				defaults: {
					duration: 0.6,
					ease: 'power1.out',
				}
			});

			gsap.set(descriptionContainer, {
				y: 0,
			})

			tl.to(descriptionContainer, {
				y: () => `-${descriptionHeight}px`,
			})
			tl.to(descriptionText, {
				autoAlpha: 1,
				duration: 0.6,
			}, "<")
			tl.to(descriptionCopyContainer, {
				height: 'auto',
				duration: 0.6,
			}, "<")

			if (canHover) {
				project.addEventListener("mouseover", () => {
					tl.play();
				})

				project.addEventListener("mouseout", () => {
					tl.reverse();
				})
			} else {
				project.addEventListener("click", () => {
					descriptionHeight = descriptionCopyContainer.offsetHeight;
          
					project.classList.toggle("active");

					if (project.classList.contains("active")) {
						tl.play();
					} else {
						tl.reverse();
					}
				})
			}


		})
	}


	// ----------------------------------------------------------------------

	// Pin Inquire button on product pages

	const inquireButton = document.querySelector('.inquire-button a');
	const wrapper = document.querySelector('.product-info .wrapper');
	const main = document.querySelector("main");

	let mm = gsap.matchMedia();

	if (inquireButton) {

		mm.add("(min-width: 768px)", () => {

			ScrollTrigger.create({
				trigger: inquireButton,
				endTrigger: main,
				// start: 'top 80%',
				start: () => {
					const startPosition = inquireButton.offsetHeight;
					return `bottom bottom-=${startPosition}px`;
				},
				end: () => {
					const endPosition = inquireButton.offsetHeight;
					return `bottom bottom-=${endPosition}px`;
				},
				pin: inquireButton,
				pinSpacing: false,
			})
		})
	}


	// ----------------------------------------------------------------------

	// Fade items in and up on scroll

	gsap.utils.toArray('.fade-in-and-up').forEach(box => {
		gsap.fromTo(box, {
			opacity: 0,
			y: 40
		}, {
			opacity: 1,
			y: 0,
			duration: 1,
			ease: "power2.out",
			scrollTrigger: {
				trigger: box,
				start: 'top 80%',
			},
		});
	});

	mm.add("(max-width: 767px)", () => {

		gsap.utils.toArray('.fade-in-and-up-mobile').forEach(box => {
			gsap.fromTo(box, {
				opacity: 0,
				y: 40
			}, {
				opacity: 1,
				y: 0,
				duration: 1,
				ease: "power2.out",
				scrollTrigger: {
					trigger: box,
					start: 'top 80%',
				},
			});
		});
	});

	// ----------------------------------------------------------------------

	// Background colour change on individual project/product pages.

	const body = document.querySelector('body');

	if (body.classList.contains('single-product') || body.classList.contains('single-project')) {
		gsap.set(body, {
			backgroundColor: '#F8F5F2',
		})
		gsap.to(body, {
			backgroundColor: '#FFFEFC',
			duration: 0.6,
			ease: 'power2.out',
			scrollTrigger: {
				trigger: body,
				start: 'top top-=10%',
				end: "top top-=100%",
				scrub: true,
			},
		})
	}

	// ----------------------------------------------------------------------


	// Pin featured product on home page
	const featuredProject = document.querySelector('.products-display .featured-product-container');

	if (featuredProject) {

		let featuredProjectMm = gsap.matchMedia();

		featuredProjectMm.add("(min-width: 1024px)", () => {
			ScrollTrigger.create({
				trigger: featuredProject,
				start: 'top top+=32', // Adjusted start value
				endTrigger: ".other-products",
				end: () => `bottom top+=${featuredProject.offsetHeight + 32}`,
				pin: true,
				pinSpacing: false,
			})
		})
	}

})

