document.addEventListener('DOMContentLoaded', function () {
  const menuToggle = document.querySelector('.menu-toggle');
  const header = document.querySelector('#site-header');
  const headerWrapper = document.querySelector('#site-header > .wrapper');
  const mainMenu = document.querySelector('#main-menu');
  const menuCloseBtn = mainMenu.querySelector('.close-btn');
  const menuOverlay = document.querySelector('.nav-overlay');

  function expandHeader() {
    const headerHeight = header.offsetHeight;
    const scrollPosition = window.scrollY || window.pageYOffset;

    if (scrollPosition >= headerHeight) {
      headerWrapper.classList.add('expanded');
    }
  }

  function collapseHeader() {
    headerWrapper.classList.remove('expanded');
  }

  header.addEventListener('mouseover', expandHeader);
  header.addEventListener('mouseout', collapseHeader);
  menuToggle.addEventListener('mouseover', expandHeader);
  menuToggle.addEventListener('mouseout', collapseHeader);


  function toggleMenu(event) {
    mainMenu.classList.toggle("expanded")
    menuToggle.classList.toggle("hidden");
    menuOverlay.classList.toggle("active");
    event.stopPropagation();
  }

  menuToggle.addEventListener("click", (event) => {
    toggleMenu(event);
  });
  menuCloseBtn.addEventListener("click", (event) => {
    toggleMenu(event);
  });

  document.addEventListener("click", event => {
    const isMenuOpen = mainMenu.classList.contains("expanded");
    const isClickInsideNav = mainMenu.contains(event.target);
    const isClickOnMenuToggle = event.target === menuToggle;

    if (isMenuOpen && !isClickInsideNav && !isClickOnMenuToggle) {
      toggleMenu(event);
    }
  })


  // Fade out rest of menu items on hover
  function handleMenuLinks(menuLinks, additionalLinks = []) {
    menuLinks.forEach((link) => {
      link.addEventListener("mouseenter", () => {
        // Fade out other links in the same set
        menuLinks.forEach((innerLink) => {
          if (link !== innerLink) {
            innerLink.classList.add("fade-out");
          }
        });
        // Fade out links in the additional set
        additionalLinks.forEach((additionalLink) => {
          additionalLink.classList.add("fade-out");
        });
      });

      link.addEventListener("mouseout", () => {
        // Remove fade-out from links in the same set
        menuLinks.forEach((innerLink) => {
          innerLink.classList.remove("fade-out");
        });
        // Remove fade-out from links in the additional set
        additionalLinks.forEach((additionalLink) => {
          additionalLink.classList.remove("fade-out");
        });
      });
    });
  }

  const mainMenuLinks = document.querySelectorAll(".main-menu ul li a");
  const socialMenuLinks = document.querySelectorAll("#site-header .social-links ul li a");
  const footerMenuLinks = document.querySelectorAll(".footer-menu ul li a");
  const footerSocialMenuLinks = document.querySelectorAll("#site-footer .social-links ul li a");

  handleMenuLinks(mainMenuLinks, socialMenuLinks);
  handleMenuLinks(socialMenuLinks, mainMenuLinks);

  handleMenuLinks(footerMenuLinks, footerSocialMenuLinks);
  handleMenuLinks(footerSocialMenuLinks, footerMenuLinks);

  const projectFilterButtons = document.querySelectorAll(".projects-page .filter");
  if (projectFilterButtons.length > 0) {
    handleMenuLinks(projectFilterButtons);
  }

  const productFilterButtons = document.querySelectorAll(".products-page .filter");
  if (productFilterButtons.length > 0) {
    handleMenuLinks(productFilterButtons);
  }


  // ------------------------------------------------------

  const upArrow = document.querySelector(".up-arrow");
  upArrow.addEventListener("click", () => {
    window.scrollTo({top: 0, behavior: "smooth"});
  })

  // ------------------------------------------------------
  
});







